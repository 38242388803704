import {createSlice} from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        token: '',
        signedIn: false,
        message: '',
        messageType: '',
        csrf: '',
        dbName: '',
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true;
        },
        onSignOutSuccess: state => {
            state.signedIn = false;
            state.token = '';
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setSessionMessage: (state, action) => {
            state.message = action.payload.message;
            state.messageType = action.payload.type;
        },
        setCsrf: (state, action) => {
            state.csrf = action.payload;
        },
        setDbName: (state, action) => {
            state.dbName = action.payload;
        },
    },
});

export const {onSignInSuccess, onSignOutSuccess, setToken, setSessionMessage, setCsrf, setDbName} =
    sessionSlice.actions;

export default sessionSlice.reducer;
