import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import appConfig from 'configs/app.config';
import {onSignOutSuccess, setSessionMessage} from '../auth/sessionSlice';

const unauthorizedCode = [401, 419];

const baseQuery = fetchBaseQuery({
    baseUrl: appConfig.apiPrefix,
    credentials: 'include',
    prepareHeaders: (headers, {getState}) => {
        const token = getState().auth.session.token;

        const dbName = getState().auth.session.dbName;

        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }

        if (dbName) {
            headers.set('database-name', `${dbName}`);
        }

        headers.set('Accept', 'application/json');

        if (!headers.has('Content-Type')) {
            headers.set('Content-Type', 'application/json');
        }

        return headers;
    },
});

const baseQueryWithLogout = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.data?.message) {
        api.dispatch(setSessionMessage({message: result?.data.message, type: 'success'}));
    }

    if (result?.error) {
        let message = result?.error.data.message;
        if (result?.error.data.message.message) {
            message = result?.error.data.message.message;
        }
        api.dispatch(setSessionMessage({message, type: 'danger'}));

        if (unauthorizedCode.includes(result?.error?.status)) {
            api.dispatch(onSignOutSuccess());
        }
    }

    return result;
};

export const apiSlice = createApi({
    reducerPath: 'reduxApi',
    baseQuery: baseQueryWithLogout,
    tagTypes: [
        'EventParam',
        'MediaFormatParam',
        'MediaParam',
        'MediaTypeParam',
        'MediaDetailParam',
        'Competitor',
        'LocationFilter',
        'LocationList',
        'ClusterStores',
        'ClusterCompetitors',
        'StoreList',
        'StoreCompaniesFilter',
        'StoreStatesFilter',
        'StoreCityFilter',
        'LocationCompaniesFilter',
        'LocationStatesFilter',
        'LocationCityFilter',
        'CallParam',
        'CallProducts',
        'ProductList',
        'ProductCategoriesFilter',
        'ActivityParam',
        'ActivityItems',
        'AreaParam',
        'PricingPolicyParam',
        'Campaign',
        'CampaignStructure',
        'CampaignProduct',
        'ProductBuyers',
        'CampaignAdditionalRule',
        'CampaignManagement',
        'Dashboard',
        'CampaignSpacePhoto',
    ],
    keepUnusedDataFor: process.env.NODE_ENV === 'development' ? 1 : 60,
    refetchOnFocus: process.env.NODE_ENV !== 'development',
    refetchOnReconnect: process.env.NODE_ENV !== 'development',
    endpoints: builder => ({}),
});
